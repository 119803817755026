import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PaymentMethod } from "../../../../graphql/schema";
import { AsyncImg } from "../../../../components/async-img/AsyncImg";
import { DigitalProductBase } from "../../../../components/digital-product-base/DigitalProductBase";
import {
  AddToCartSelector,
  AddToCartSelectorProductPackage,
  AddToCartSelectorProps,
} from "../../../../components/add-to-cart-selector/AddToCartSelector";
import { DigitalProductExclusiveOfferModal } from "../../../../components/digital-product-exclusive-offer/DigitalProductExclusiveOfferModal";
import { formatLocaleDate, timeLeft } from "../../../../services/formatDate";
import { OfferSubmitMethod } from "../../../../components/digital-product-exclusive-offer/offers/Offers";

import styles from "./the-view.module.scss";

export interface TheViewExclusiveOfferMembership {
  id: string;
  labelText?: string | null;
  datePurchased: string;
  eurPrice: string;
  dagPrice: string;
}

export interface TheViewProps extends Pick<AddToCartSelectorProps, "hasAccess"> {
  className?: string;
  publicUrl?: string;
  packages?: AddToCartSelectorProductPackage[] | null;
  detail?: string;
  exclusiveOffer?: {
    dateStart: Date;
    dateEnd: Date;
    expiresInMS: number;
    memberships: TheViewExclusiveOfferMembership[];
    dagsBalance: number;
    availablePaymentMethods: PaymentMethod[];
    onSubmit(method: OfferSubmitMethod, membershipIds: string[], totalPrice: string): Promise<void>;
  };
}

export const TheView: React.FC<TheViewProps> = ({
  className,
  publicUrl,
  packages,
  detail,
  exclusiveOffer,
  ...props
}) => {
  const [t, i18n] = useTranslation();
  const [isExclusiveOfferOpen, setIsExclusiveOfferOpen] = useState(false);
  const [selectedMembershipIds, setSelectedMembershipIds] = useState<string[]>([]);

  function isMembershipChecked(row: { id: string }) {
    return selectedMembershipIds.includes(row.id);
  }

  function handleMembershipChecked(membershipId: string) {
    setSelectedMembershipIds(
      selectedMembershipIds.includes(membershipId)
        ? selectedMembershipIds.filter((id) => id !== membershipId)
        : [...selectedMembershipIds, membershipId],
    );
  }

  const membershipTotalInEur = (exclusiveOffer?.memberships ?? [])
    .filter(isMembershipChecked)
    .reduce((total, row) => total + Number(row.eurPrice), 0);
  const membershipTotalInDag = (exclusiveOffer?.memberships ?? [])
    .filter(isMembershipChecked)
    .reduce((total, row) => total + Number(row.dagPrice), 0);

  return (
    <DigitalProductBase
      title={t("The View")}
      logo={<AsyncImg className={className} src="/images/TheViewLogo.svg" />}
      publicUrl={publicUrl}
      detail={detail ?? ""}
      price={t("No monthly fees")}
      exclusiveOffer={
        exclusiveOffer && (
          <>
            <strong>
              <i>{t("Exclusive offer from The View")}</i>
            </strong>
            {" | "}
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  "Because you have purchased The View membership(s) after {{date}} you are exclusively invited to purchase the same membership(s) again, but by getting the second one <strong>by using dagcoins!</strong>",
                  { date: formatLocaleDate(exclusiveOffer.dateStart, i18n.language) },
                ),
              }}
            />
          </>
        )
      }
      onExclusiveOfferClick={() => setIsExclusiveOfferOpen(true)}
      exclusiveOfferExpiryDate={exclusiveOffer?.dateEnd}
      exclusiveOfferTimeLeftMS={exclusiveOffer?.expiresInMS}
      producerName="The View BV / The View Meta Technologies LLC"
    >
      <AddToCartSelector
        className={styles["access-product-link"]}
        labelClassName={styles["link-label"]}
        packages={packages || []}
        modalPanelProps={{
          title: t("Select the packages"),
          caption: t(
            "The View has {{count}} different packages, select the one(s) that you want and click “Add to cart”. You can adjust the quantity of the packages inside the cart, on the next page.",
            { count: packages?.length },
          ),
          label: "The View",
        }}
        hasAccess={props.hasAccess}
      />

      {exclusiveOffer && isExclusiveOfferOpen && (
        <DigitalProductExclusiveOfferModal
          label={t("The View")}
          description={t(
            "Below you can find The View memberships you are eligible to purchase again by using dagcoin payments. This exclusive offer is <strong>ending in {{timeLeft}}</strong>, in {{date}}.",
            {
              date: formatLocaleDate(exclusiveOffer.dateEnd, i18n.language),
              timeLeft: timeLeft(exclusiveOffer.expiresInMS, t),
            },
          )}
          close={() => setIsExclusiveOfferOpen(false)}
          onMembershipChecked={handleMembershipChecked}
          memberships={exclusiveOffer.memberships.map((row) => ({ ...row, isChecked: isMembershipChecked(row) }))}
          totalPriceInEur={membershipTotalInEur}
          totalPriceInDags={membershipTotalInDag}
          dagsBalance={exclusiveOffer.dagsBalance}
          availablePaymentMethods={exclusiveOffer.availablePaymentMethods}
          onSubmit={(method) => exclusiveOffer.onSubmit(method, selectedMembershipIds, membershipTotalInDag.toString())}
        />
      )}
    </DigitalProductBase>
  );
};
